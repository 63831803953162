<template>
      
  <section class="dashboard">
    
    <router-link class="rounded-button float-right bg-gradient-primary" :to="link('/create')">+</router-link>
    <div class="page-header">
      <h3 class="page-title">
        <span class="page-title-icon bg-gradient-primary text-white mr-2">
          <i class="mdi mdi-check"></i>
        </span> {{$options.label }}</h3>
        
    </div>
 
    <div class="row">
      <div class="col-12 grid-margin">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title">Elige el desarrollo</h4>


            <div class="container" v-if="hasItems"> 

              <figure v-for="(item,index) in getItems" :key="index" class="snip1321">
                
                <!--
                <div class="item4">
                  <h4>{{ item.name }}</h4>
                  <img class="" :src="$thumb(item.featured_image)"/>
                </div>
                -->
              
                <!--
                <div class="item2">
                  <img class="" :src="$thumb(item.featured_image)"/>
                </div>
                -->

                <router-link :to="link('/map/'+item.id)">
                <img :src="$thumb(item.featured_image)"/>
              
                  <div class="square">
                    <div></div>
                  </div>
                  <h4>{{ item.name }}</h4>
                  <!--<p>{{ item.phases.length }} fases</p>-->
                  <!--
                  <figcaption>
                    <i class="ion-upload"></i>


                    <h5>Fases</h5>
                    <ul>

                        <li v-for="(phase,indx) in item.phases" :key="indx">
                          
                        <router-link :to="link('/stages/update/'+item.id)">{{ phase.name }}</router-link>
                        </li>

                    </ul>

                  </figcaption><a href="#"></a>
                  -->
                </router-link>
              </figure>



            </div>

            <div v-else>Todavía no hay desarrollos que contengan fases</div>



          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>

import api from '@/util/api.js';


export default {
  routeName:'quotations',
  label:'Cotizaciones',
  name: 'listQuotations',
  components: {
  },
  data() {
    return {
      items:[],
      listing:false
     
    };
  },


  computed:{

    hasItems(){

      return this.items.length>0?true:false;

    },  
    isReady(){

      return this.items?true:false;
    },
    getItems(){

        return this.items;

      },


  },


  methods: {



    setItems(data){

      let items=[];
        for(let i in data){
          if(data[i].phases.length>0){
            items.push(data[i]);
          }
        }


        this.items=items;
    },
    getDevelopments() {

      this.listing=true;
      
      api.get('/developments?expand=phases').then(response => {
        

        this.setItems(response.data);

        
      }).catch(error => {
        console.log(error);
      }).finally(()=>{

        this.listing=false;

      });
    },


    deleteMe(id,index){





      if(confirm('¿Desea eliminar el elemento?')) {

              api.delete(this.me(id)).then(()=>{

                this.items.splice(index,1);
                this.$forceUpdate();
                
              
              }).catch(error=>{

                console.log(error);
                
              });

        }

      }
          

      
  },

  mounted(){

    
    this.getDevelopments();
  }

}



</script>



<style lang="scss" scoped>


.container{display:flex}
figure.snip1321 {
  position: relative;
  overflow: hidden;
  margin: 10px;
  min-width: 230px;
  max-width: 315px;
  width: 100%;
  color: #000000;
  background: #000000;
  text-align: center;
  -webkit-perspective: 50em;
  perspective: 50em;
}
figure.snip1321 * {
  -webkit-box-sizing: padding-box;
  box-sizing: padding-box;
  -webkit-transition: all 0.2s ease-out;
  transition: all 0.2s ease-out;
}
figure.snip1321 img {
  max-width: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    width: 100%;
    vertical-align: top;
    height: 192px;
}
figure.snip1321 figcaption {
  top: 50%;
  left: 20px;
  right: 20px;
  position: absolute;
  opacity: 0;
  z-index: 1;
}
figure.snip1321 h2{margin:0}
figure.snip1321 h4 {
  color: #fff;
    font-size: 18px;
    font-weight: bold !important;
    position: absolute;
    top: 50%;
    width: 100%;
    margin-top: -9px;
    line-height: 1;
    transition: opacity .3s ease;
}

figure.snip1321:hover h4{
  /*opacity: 0;*/
}

figure.snip1321 h2 {
  font-weight: 600;
}
figure.snip1321 h4 {
  font-weight: 400;
  text-transform: uppercase;
}
figure.snip1321 i {
  font-size: 32px;
}

/*
figure.snip1321:after {
  background-color: #ffffff;
  position: absolute;
  content: "";
  display: block;
  top: 20px;
  left: 20px;
  right: 20px;
  bottom: 20px;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
  -webkit-transform: rotateX(-90deg);
  transform: rotateX(-90deg);
  -webkit-transform-origin: 50% 50%;
  -ms-transform-origin: 50% 50%;
  transform-origin: 50% 50%;
  opacity: 0;
}

figure.snip1321:hover figcaption,
figure.snip1321.hover figcaption {
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  opacity: 1;
  -webkit-transition-delay: 0.2s;
  transition-delay: 0.2s;
}
figure.snip1321:hover:after,
figure.snip1321.hover:after {
  -webkit-transform: rotateX(0);
  transform: rotateX(0);
  opacity: 0.9;
}

*/


figure.snip1321 ul li{list-style-type:none;padding-left: 0;}


figure.snip1321 .square {
  height: 78px;
  width: 78px;
  overflow: hidden;
  position: absolute;
  top: 50%;
  left: 50%;
  content: '';
  -webkit-transform: rotate(45deg) translate(-50%, -50%);
  transform: rotate(45deg) translate(-50%, -50%);
  -webkit-transform-origin: 0 0;
  transform-origin: 0 0;
}
figure.snip1321 .square:before,
figure.snip1321 .square:after,
figure.snip1321 .square div:before,
figure.snip1321 .square div:after {
  background-color: #ffffff;
  position: absolute;
  content: "";
  display: block;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}
figure.snip1321 .square:before,
figure.snip1321 .square:after {
  width: 65%;
  height: 2px;
}
figure.snip1321 .square div:before,
figure.snip1321 .square div:after {
  width: 2px;
  height: 65%;
}
figure.snip1321 .square:before,
figure.snip1321 .square div:before {
  left: 0;
  top: 0;
}
figure.snip1321 .square:after,
figure.snip1321 .square div:after {
  bottom: 0;
  right: 0;
}



figure.snip1321 a {
text-decoration: underline;
}
figure.snip1321:hover img,
figure.snip1321.hover img {
  opacity: 0.25;
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
figure.snip1321:hover h2,
figure.snip1321.hover h2 {
  opacity: 1;
  -webkit-transform: translateY(0px);
  transform: translateY(0px);
}
figure.snip1321:hover p,
figure.snip1321.hover p {
  opacity: 1;
  -webkit-transform: translateY(0px) scale(1);
  transform: translateY(0px) scale(1);
}
figure.snip1321:hover .square:before,
figure.snip1321.hover .square:before {
  width: 0%;
}
figure.snip1321:hover .square div:before,
figure.snip1321.hover .square div:before {
  height: 0%;
}
figure.snip1321:hover .square:after,
figure.snip1321.hover .square:after {
  width: 0%;
}
figure.snip1321:hover .square div:after,
figure.snip1321.hover .square div:after {
  height: 0%;
}


.item {
  transform: perspective(750px) translate3d(0px, 0px, -250px) rotateX(27deg)
    scale(0.9, 0.9);
  border-radius: 20px;
  border: 5px solid #e6e6e6;
  box-shadow: 0 70px 40px -20px rgba(0, 0, 0, 0.2);
  transition: 0.4s ease-in-out transform;

  &:hover {
    transform: translate3d(0px, 0px, -250px);
  }
}



.item2 {
  overflow: hidden;
  width:200px;
  height:200px;
  transform:
    rotateX(51deg)
    rotateZ(43deg);
  transform-style: preserve-3d;
  border-radius: 32px;
  box-shadow:
    1px 1px 0 1px #f9f9fb,
    -1px 0 28px 0 rgba(34, 33, 81, 0.01),
    28px 28px 28px 0 rgba(34, 33, 81, 0.25);
  transition:
    .4s ease-in-out transform,
    .4s ease-in-out box-shadow;

  &:hover {
    transform:
      translate3d(0px, -16px, 0px)
      rotateX(51deg)
      rotateZ(43deg);
    box-shadow:
      1px 1px 0 1px #f9f9fb,
      -1px 0 28px 0 rgba(34, 33, 81, 0.01),
      54px 54px 28px -10px rgba(34, 33, 81, 0.15);
  }
}




.item3 {
  transform:
    perspective(1000px)
    rotateX(4deg)
    rotateY(-16deg)
    rotateZ(4deg);
  box-shadow: 24px 16px 64px 0 rgba(0, 0, 0, 0.08);
  border-radius: 2px;
}


.item4{
  display: inline-block;
  background: transparent !important;
  -webkit-transform: perspective(1000px) rotateX(4deg) rotateY(-16deg) rotateZ(4deg);
    transform: perspective(1071px) rotateX(-19deg) rotateY(-44deg) rotateZ(4deg);
    -webkit-box-shadow: 24px 16px 64px 0 rgba(0, 0, 0, 0.08);
    box-shadow: 24px 16px 64px 0 rgba(0, 0, 0, 0.08);
    border-radius: 2px;
    position: relative;
    top: 97px;
}

</style>